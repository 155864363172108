.App {
  text-align: center;
}

.Footer-logo {
  height: 8vmin;
  pointer-events: none;
}

.AppLogo {
  height: 40vmin;
  pointer-events: none;
}

/* @media (prefers-reduced-motion: no-preference) {
  .AppLogo {
    animation: AppLogo-spin infinite 20s linear;
  }
} */
.dwnld {
  margin-top: 6%;
  display: flex;
  -webkit-display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
  margin-right: 8%;
}

.mobileInp {
  margin-left: 5%;
  margin-right: 5%;
  width: auto;
}

.mobileInp form {
  position: relative
}

.mobileInp .formblock {
  display: flex;
  -webkit-display: flex;
  position: relative
}

.mobileInp .phnIntCode {
  position: absolute;
  left: 8px;
  top: 12px;
  color: #47535d;
  font-weight: 700;
  pointer-events: none
}
button,input,select,textarea {
  font-family: Roboto,sans-serif
}
.btn {
  padding: 12px 10px;
  min-width: 140px;
  font-size: 14px;
  background-color: '#f7b819';
}

.get_apk input {
  width: calc(100% - 140px);
  border: none;
  font-size: 15px;
  height: 44px;
  color: #47535d;
  border-radius: 4px;
  padding: 5px 10px 5px 40px
}

.get_apk .btn {
  font-size: 14px;
  min-width: 130px;
  margin-left: 10px
}

.gold {
  background-color: #f7b819;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes AppLogo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
